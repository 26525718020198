import { Link } from "react-router-dom";

const Sidebar = () => {
    const pathName = window.location.pathname;
    return (

        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className={`${pathName === "/timetracker" && "mm-active"}`}>
                            <Link
                                className={`waves-effect ${pathName === "/timetracker" && "active"
                                    }`}
                                to={"/timetracker"}
                            >
                                <i className="mdi mdi-home-variant-outline"></i>
                                <span>Time Tracker</span>
                            </Link>
                        </li>

                        {/* <li className="menu-title">Analyze</li>

                        <li className="menu-title">Manage</li> */}
                        {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead')) &&
                            <li className={`${pathName === "/projects" && "mm-active"}`}>
                                <Link
                                    className={`waves-effect ${pathName === "/projects" && "active"
                                        }`}
                                    to={"/projects"}
                                >
                                    <i className="mdi mdi-briefcase-variant-outline"></i>
                                    <span>Projects</span>
                                </Link>
                            </li>
                        }
                        {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead')) &&
                            <>
                                <li className={`${pathName === "/reports" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/reports" && "active"
                                            }`}
                                        to={"/reports"}
                                    >
                                        <i className="ri-bar-chart-line"></i>
                                        <span>Reports</span>
                                    </Link>
                                </li>
                            </>
                        }
                        {localStorage.getItem('ksp_roles')?.includes('admin') &&
                            <>
                                <li className={`${pathName === "/project-settings" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/project-settings" && "active"
                                            }`}
                                        to={"/project-settings"}
                                    >
                                        <i className="mdi mdi-briefcase-variant-outline"></i>
                                        <span>Project Settings</span>
                                    </Link>
                                </li>
                                <li className={`${pathName === "/team" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/team" && "active"
                                            }`}
                                        to={"/team"}
                                    >
                                        <i className="mdi mdi-account-group-outline"></i>
                                        <span>Team</span>
                                    </Link>
                                </li>

                                <li className={`${pathName === "/clients" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/clients" && "active"
                                            }`}
                                        to={"/clients"}
                                    >
                                        <i className="mdi mdi-account-circle-outline"></i>
                                        <span>Client</span>
                                    </Link>
                                </li>
                                <li className={`${pathName === "/categories" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/categories" && "active"
                                            }`}
                                        to={"/categories"}
                                    >
                                        <i className="mdi mdi-account-circle-outline"></i>
                                        <span>Categories</span>
                                    </Link>
                                </li>
                                <li className={`${pathName === "/sub-categories" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/sub-categories" && "active"
                                            }`}
                                        to={"/sub-categories"}
                                    >
                                        <i className="mdi mdi-account-circle-outline"></i>
                                        <span>SubCategories</span>
                                    </Link>
                                </li>

                                <li className={`${pathName === "/settings" && "mm-active"}`}>
                                    <Link
                                        className={`waves-effect ${pathName === "/settings" && "active"
                                            }`}
                                        to={"/settings"}
                                    >                                <i className="mdi mdi-cog"></i>
                                        <span>Settings</span>

                                    </Link>
                                </li>
                            </>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Sidebar;