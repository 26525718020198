import { all } from "redux-saga/effects";
import categorySaga from "./category/saga";
import commonSaga from "./common/saga";
import authSaga from "./auth/saga";
import teamSaga from "./team/saga";
import subCategorySaga from "./sub-category/saga";
import settingSaga from "./settings/saga";
import clientSaga from "./clients/saga";
import projectSettingSaga from "./project-settings/saga";
import projectSaga from "./projects/saga";
import timeTrackSaga from "./timetrack/saga";
import taskSaga from "./tasks/saga";
import reportSaga from "./reports/saga";

export default function* rootSaga() {
    yield all([
        commonSaga(),
        categorySaga(),
        subCategorySaga(),
        authSaga(),
        teamSaga(),
        settingSaga(),
        clientSaga(),
        projectSettingSaga(),
        projectSaga(),
        timeTrackSaga(),
        taskSaga(),
        reportSaga()
    ]);
};
