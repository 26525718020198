import { Button, Dropdown, Form, Modal, Pagination } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import teamActions from "../../redux/team/actions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { debounce } from "lodash";
import ReactSelect from "react-select";
import { DropdownIndicator } from "../../utils/helpers";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import commonActions from "../../redux/common/actions";

const validationSchema = Yup.object().shape({
    email: Yup.string().required()
});

const validationSchema2 = Yup.object().shape({
    team_id: Yup.array().required()
});

const validationSchema1 = Yup.object().shape({
    billable_rate: Yup.number().required(),
    name: Yup.string().required(),
    team_id: Yup.array().required(),
    roles: (localStorage.getItem('ksp_roles')?.includes('super_admin') || localStorage.getItem('ksp_roles')?.includes('admin')) ? Yup.array().required() : Yup.array().nullable()
});

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none"
    }),
};

const Members = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const { register: register1, control, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm({ resolver: yupResolver(validationSchema1) });
    const form = useRef();
    const userSelect = useRef([]);
    const userName = useRef();
    const userForm = useRef();
    const userForm1 = useRef();
    const pageCount = useRef();
    const { users, roles, userPagination, userFilterParams } = useSelector(state => state.teamReducer);
    const [modalDetails, setModalDetails] = useState(false);
    const [modalUserTeamDetails, setModalUserTeamDetails] = useState(null);
    const [inviteModal, setInviteModal] = useState(false);
    const dispatch = useDispatch();

    const handleAddOpen = () => {
        setInviteModal(true);
    }

    const perPage = (e) => {
        dispatch({
            type: teamActions.SET_USER_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    const handleClose = () => {
        setInviteModal(false);
        reset({
            email: null
        })
    }

    const handleUserClose = () => {
        setModalDetails(null);
        setModalUserTeamDetails(null);
        reset1({
            name: "",
            billable_rate: "",
            status: true,
            roles: []
        });
    }

    const handleEditOpen1 = (el, e) => {
        if (e === "3") {
        } else if (e === "2") {
            setModalUserTeamDetails([
                ...(el.teams?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
            ]);
            reset1({
                name: el.name,
                billable_rate: el.billable_rate,
                status: el.status ? true : false,
                roles: [
                    ...(el.roles?.map(val => { return { ...val, label: val.name, value: val.id } })),
                ],
                'team_id': [
                    ...(el.teams?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
                ]
            });
            setModalDetails(el);
        }
    }

    const onSubmit = (data) => {
        dispatch({ type: teamActions.INVITE_MAIL, payload: data })
    }

    const onUserSubmit = (data) => {
        data['id'] = modalDetails?.id;
        var bool = true;
        if (data['status'] === 1) {
            bool = true;
        } else if (data['status'] === "1") {
            bool = true;
        } else if (data['status'] === "true") {
            bool = true;
        } else if (data['status'] === true) {
            bool = true;
        } else {
            bool = false;
        }
        data['status'] = bool;
        if (!data['id']) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'User Id is required.', status: false
                }
            });
        } else if (data['roles'].length === 0) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'Role is required.', status: false
                }
            });
        } else {
            dispatch({ type: teamActions.UPDATE_USER, payload: data })
        }
    }

    // const onUserTeamSubmit = (data) => {
    //     dispatch({
    //         type: teamActions.UPDATE_USER_TEAM, payload: {
    //             user_id: modalUserTeamId,
    //             team_id: data.team_id.map(el => el.value),
    //         }
    //     })
    // }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };

    const formUserTeamSubmit = (e) => {
        e.preventDefault();
        userForm.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };

    const paginate = (page) => {
        dispatch({
            type: teamActions.SET_USER_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: teamActions.SET_USER_FILTER_PARAMS, payload: {
                name: userName.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: teamActions.SET_USER_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: teamActions.SET_USER_FILTER_PARAMS, payload: {
                status: userSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: teamActions.SET_USER_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);

    useEffect(() => {
        handleClose();
        handleUserClose();
    }, [users]);

    useEffect(() => {
        dispatch({ type: teamActions.GET_USERS });
        dispatch({ type: teamActions.GET_ROLES });
    }, [userFilterParams, userPagination]);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <select className="form-select" onChange={handleStatus} defaultValue={userFilterParams?.status} ref={userSelect}>
                                        <option value={true}>Show Active</option>
                                        <option value={false}>Show Archive</option>
                                        <option value="all">Show All</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <form className="app-search client-search">
                                        <div className="position-relative">
                                            <input type="text" onChange={debouncedChangeHandler} defaultValue={userFilterParams?.name} className="form-control" ref={userName} placeholder="Search by name" />
                                            <span className="ri-search-line"></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" onClick={handleAddOpen}>Invite Member</button>
                        </div>
                    </div>
                    <div className="table-responsive mt-30">
                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Billable Rate (USD)</th>
                                    <th>Role</th>
                                    <th>Team</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.data?.map(el =>

                                    <tr key={'users-data' + el.id}>
                                        <td width="300">
                                            <h6><a href>{el.name}</a></h6>
                                        </td>
                                        <td width="300">
                                            <h6>{el.email}</h6>
                                        </td>
                                        <td width="500">
                                            <h6>{el.billable_rate}</h6>
                                        </td>
                                        <td>
                                            {el?.roles?.map(e => <button key={'users-button' + el.id} type="button" className="btn btn-info btn-sm waves-effect waves-light">{e.name}</button>)}
                                        </td>
                                        <td>
                                            {el?.teams?.map(e => <button key={'teams-button' + el.id} type="button" className="btn btn-info btn-sm waves-effect waves-light">{e.name}</button>)}
                                        </td>
                                        {/* <td>
                                            <p><i className="far fa-edit"></i> Group</p>
                                        </td> */}
                                        <td width="50">
                                            <Dropdown onSelect={(e) => handleEditOpen1(el, e)}>
                                                <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                    <div className="btn-group">
                                                        <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                            <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu align={'end'} >
                                                            <Dropdown.Item eventKey="2">Edit User</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </div>
                                            </Dropdown>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <select onChange={perPage} ref={pageCount}>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>

                    </div>
                    {users?.data?.length &&
                        <Pagination>
                            {<Pagination.First onClick={() => users?.first_page_url ? paginate(1) : ""} />}
                            {<Pagination.Prev onClick={() => users?.prev_page_url ? paginate(users?.current_page - 1) : ""} />}
                            <Pagination.Item>{users?.from} - {users?.to} of {users?.total}</Pagination.Item>
                            {<Pagination.Next onClick={() => users?.next_page_url ? paginate(users?.current_page + 1) : ""} />}
                            {<Pagination.Last onClick={() => users?.last_page_url ? paginate(users?.last_page) : ""} />}
                        </Pagination>
                    }
                </div>
                <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header py-3 px-4">
                                <h5 className="modal-title" id="modal-title">Add full members</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body p-4">
                                <form className="needs-validation" name="event-form" id="form-event" noValidate>
                                    <div className="row"></div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input data-parsley-type="alphanum" type="text" className="form-control" required="" defaultValue="Ks Builders" />
                                        </div>

                                        <hr />
                                    </div>
                                    <div className="col-12 text-end">
                                        <button type="button" className="btn btn-light me-1" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-success" id="btn-save-event">ADD</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={inviteModal} onHide={handleClose}>
                    <Modal.Header className="py-3 px-4" closeButton>
                        <Modal.Title className="h5">Add Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                            <div className="row"></div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <Form.Control name="email" {...register('email')} isInvalid={!!errors.email} placeholder="Enter the email" />
                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                </div>
                                <hr />
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="text-end">
                        <Button className="btn btn-light me-1" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn-success" onClick={formSubmit}>
                            Invite
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={!!modalDetails} onHide={handleUserClose}>
                    <Modal.Header className="py-3 px-4" closeButton>
                        <Modal.Title className="h5">{modalDetails?.name ? "Update User" : "Create Team"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form ref={userForm} onSubmit={handleSubmit1(onUserSubmit)}>
                            <div className="row"></div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <Form.Control name="name" {...register1('name')} isInvalid={!!errors1.name} placeholder="Enter the name" />
                                    <Form.Control.Feedback type="invalid">{errors1.name?.message}</Form.Control.Feedback>
                                </div>
                                <hr />
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Billable Rate</label>
                                    <Form.Control name="billable_rate" type="number" {...register1('billable_rate')} isInvalid={!!errors1.billable_rate} placeholder="Enter the name" />
                                    <Form.Control.Feedback type="invalid">{errors1.billable_rate?.message}</Form.Control.Feedback>
                                </div>
                                <hr />
                            </div>
                            {(localStorage.getItem('ksp_roles')?.includes('super_admin') || localStorage.getItem('ksp_roles')?.includes('admin')) &&
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Roles</label>
                                        <Controller
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <ReactSelect
                                                        styles={style}
                                                        {...field}
                                                        className="w-100"
                                                        inputRef={field.ref}
                                                        components={{ DropdownIndicator }}
                                                        name={"roles"}
                                                        isMulti
                                                        options={[
                                                            ...(roles?.map(val => { return { ...val, label: val.name, value: val.id } })),
                                                        ]}
                                                        isClearable={true}
                                                    />
                                                );
                                            }}
                                            name="roles"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors1.roles?.message}</Form.Control.Feedback>
                                    </div>
                                </div>
                            }
                            {(localStorage.getItem('ksp_roles')?.includes('super_admin') || localStorage.getItem('ksp_roles')?.includes('admin')) &&
                                <div className="col-12">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Team</label>
                                            <Controller
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <AsyncSelect
                                                            styles={style}
                                                            {...field}
                                                            className="w-100"
                                                            inputRef={field.ref}
                                                            components={{ DropdownIndicator }}
                                                            name={"team_id"}
                                                            defaultOptions={modalUserTeamDetails?.length > 0 ? modalUserTeamDetails.map(el => { return { id: el?.id, value: el?.value, label: el?.label } }) : []}
                                                            isMulti
                                                            openMenuOnClick={false}
                                                            isClearable={true}
                                                            getOptionLabel={(e) => e?.label}
                                                            getOptionValue={(e) => e?.value}
                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                if (inputValue.trim().length > 2) {
                                                                    const data = await axios.get(`${API_URL}/teams-search/${inputValue}`,
                                                                        {
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                        })
                                                                    callback(data.data.teams.map(el => { return { name: el.name, label: el.name, value: el.id, id: el?.id } }));
                                                                } else {
                                                                    callback(null);
                                                                }
                                                            }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                        />
                                                    );
                                                }}
                                                name="team_id"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors1.team_id?.message}</Form.Control.Feedback>
                                        </div>
                                    </div>
                                </div>}
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Status</label>
                                    <select className="form-select form-control" name="status" {...register1("status")}>
                                        <option value={true}>Active</option>
                                        <option value={false}>InActive</option>
                                    </select>
                                </div>
                                <hr />
                            </div>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="text-end">
                        <Button className="btn btn-light me-1" onClick={handleUserClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn-success" onClick={formUserTeamSubmit}>
                            {modalDetails?.name ? 'Update' : 'Create'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </>
    )
};
export default Members;